import { CurrencyPipe, DatePipe, NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { LoadingSpinnerComponent } from '../../loading-spinner/loading-spinner.component';

@Component({
  standalone: true,
  selector: 'codecraft-program-card',
  template: `
    <div
      *ngIf="program; else loading"
      class="card h-100 d-flex flex-column justify-content-between p-2"
    >
      <div class="card-header d-block p-0">
      <a
        href="https://app.codecraftworks.com/programs/{{ program.id }}"
      >
        <img
          class="card-img w-100"
          src="{{ program.pictureUrl }}"
          alt="{{ program.name }}"
        />
      </a>
      </div>
      <div footer class="card-footer p-2 card-text" *ngIf="program">
        <p class="h6">
        <a
        href="https://app.codecraftworks.com/programs/{{ program.id }}"
      >{{ program.name }}</a>
        </p>
        <p>
          <span *ngIf="program.level">Level {{ program.level }}</span>
          <span *ngIf="program.level && program.ageGroup">, </span>
          <span *ngIf="program.ageGroup">Ages {{ program.ageGroup }}</span>
          <span
            *ngIf="
              (program.ageGroup || program.level) &&
              program.timeAndDate &&
              program.timeAndDate.startDateTime
            "
            >,
          </span>
          <span *ngIf="program.timeAndDate && program.timeAndDate.startDateTime">
            Start Date {{ program.timeAndDate.startDateTime | date:'shortDate' }}
          </span>
        </p>
        <p *ngIf="program && program.stripe && program.stripe.price">
          {{ program.stripe.price / 100 | currency }}
          <span *ngIf="program.stripe.type === 'plan'">
            / {{ program.stripe.frequency }}
          </span>
        </p>
      </div>
    </div>
    <ng-template #loading>
      <codecraft-loading-spinner />
    </ng-template>
  `,
  styles: [`
    :host {
      display: block;
      max-width: 512px;
      min-width: 256px;
    }

    :host ::ng-deep a {
      text-decoration: none;
    }

    .card{
      background: linear-gradient(
        30deg,
        #d367c1 10%,
        #dedf40 25%,
        #62cb5c 50%,
        #00bbcb 75%,
        #ab79d6 90%
      );
    }

    .card-footer {
      background: white;
    }
  `],
  imports: [LoadingSpinnerComponent, NgIf, DatePipe, CurrencyPipe],
})
export class ProgramCardComponent implements OnInit {
  program;

  @Input()
  id: string;

  async ngOnInit() {
    if (this.id) {
      this.program = await this.getProgram(this.id);
    }
  }

  public getProgram(id: string): Promise<{ id: string; name: string; pictureUrl: string; timeAndDate: { startDateTime: Date; }; }> {
    return fetch(`https://firestore.googleapis.com/v1/projects/codecraft-platform/databases/(default)/documents/programs/${id}`)
      .then(response => response.json())
      .then(data => {
        const fields = data.fields;
        const program =  {
          id,
          name: fields.name.stringValue,
          pictureUrl: fields.pictureUrl.stringValue,
          timeAndDate: fields?.timeAndDate?.mapValue?.fields?.startDateTime?.timestampValue && { startDateTime : new Date(fields.timeAndDate.mapValue.fields.startDateTime.timestampValue) } || null,
          ageGroup: fields?.ageGroup?.stringValue || null,
          level: fields?.level?.integerValue || null,
          stripe: {
            price: fields?.stripe?.mapValue?.fields?.price?.integerValue || null,
            type: fields?.stripe?.mapValue?.fields?.type?.stringValue || null,
            frequency: fields?.stripe?.mapValue?.fields?.frequency?.stringValue || null,
          },
        }
        return program;
      })
      .catch(error => {
        console.error('Error fetching program:', error);
        return null;
      });
  }
}
