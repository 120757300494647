import { AsyncPipe, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { LoadingSpinnerComponent } from '../loading-spinner/loading-spinner.component';
import { CodecraftQuizCompletedPageComponent } from './features/completed-page.component';
import { CodecraftQuizDetailPageComponent } from './features/detail-page.component';
import { CodecraftQuizQuestionPageWrapperComponent } from './features/question-page-wrapper.component';
import { CodecraftQuizErrorPageComponent } from './ui/error-page.component';
import { QuizService, QuizState } from './util/quiz.service';

@Component({
  standalone: true,
  selector: 'codecraft-quiz',
  template: `
    <div *ngIf="uid && quizState$ | async as quizState; else loading">
      <div [ngSwitch]="quizState.component">
        <codecraft-quiz-detail-page
          *ngSwitchCase="'not-started'"
        ></codecraft-quiz-detail-page>
        <codecraft-quiz-detail-page
          *ngSwitchCase="'resuming'"
        ></codecraft-quiz-detail-page>
        <codecraft-quiz-question-page-wrapper
          *ngSwitchCase="'in-progress'"
        ></codecraft-quiz-question-page-wrapper>
        <codecraft-quiz-completed-page
          *ngSwitchCase="'completed'"
        ></codecraft-quiz-completed-page>
        <codecraft-quiz-error-page
          *ngSwitchCase="'error'"
          [error]="quizState.error"
        ></codecraft-quiz-error-page>
        <codecraft-quiz-error-page
          *ngSwitchDefault
          [error]="'Invalid quiz state'"
        ></codecraft-quiz-error-page>
      </div>
    </div>
    <ng-template #loading>
      <p class="h1">Quiz Loading</p>
      <hr />
      <codecraft-loading-spinner></codecraft-loading-spinner>
    </ng-template>
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  imports: [
    NgIf,
    AsyncPipe,
    NgSwitch,
    NgSwitchCase,
    CodecraftQuizDetailPageComponent,
    CodecraftQuizQuestionPageWrapperComponent,
    CodecraftQuizCompletedPageComponent,
    CodecraftQuizErrorPageComponent,
    LoadingSpinnerComponent,
  ],
})
export class CodecraftQuizComponent implements OnInit {
  @Input() quizid!: string;
  @Input() uid!: string;

  quizState$!: Observable<QuizState>;
  constructor(private quizService: QuizService) {}

  async ngOnInit(): Promise<void> {
    if (!this.uid) {
      this.quizService.setQuizState({
        component: 'error',
        error: 'Missing uid',
      });
      return;
    }
    if (!this.quizid) {
      this.quizService.setQuizState({
        component: 'error',
        error: 'Missing quizid',
      });
      return;
    }
    try {
      await this.quizService.initialize(this.uid, this.quizid);
      this.quizState$ = this.quizService.getQuizState$();
    } catch (error) {
      console.error('Init error:', error);
      this.quizService.setQuizState({
        component: 'error',
        error: 'Quiz component init error: ' + error,
      });
    }
  }
}
